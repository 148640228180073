import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Typography = makeShortcode("Typography");
const TextReveal = makeShortcode("TextReveal");
const Paper = makeShortcode("Paper");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "so-how-can-you-best-support-your-teen-if-a-loved-one-dies"
    }}>{`So how can you best support your teen if a loved one dies?`}</h2>
    <Typography variant="h5" color="primary" mdxType="Typography">
  <b>Click here for some tips:</b>
    </Typography>
    <br />
    <TextReveal id="supportWhenDeath" mdxType="TextReveal">
  <ul>
    <li>
      Let your teen know you will be there for them when they are ready to talk. Make yourself available when they do
      want to talk with you. Focus on listening and empathising.
    </li>
    <li>
      Let them know that it is okay to feel however they feel (e.g. sadness, overwhelm, anger, worry, separation
      anxiety) and that there is no right or wrong way to grieve.
    </li>
    <li>
      Give them time and space to grieve in ways that work for them (e.g. allowing them to have a space in the house
      where they can have some time to themselves).
    </li>
    <li>
      Regularly check in with them. Ask how they feel, what they are thinking about, how they are coping, and whether
      they have any questions.
    </li>
    <li>Help them find ways to express themselves (e.g. journaling, drawing, playing music).</li>
    <li>
      Help them find ways to honour the death of their loved one while following the local regulations. You might need
      to be creative - e.g. light a candle, write a letter, plant a flower or tree.
    </li>
    <li>
      If your teen was unable to see the loved one before they died, assure them that this was out of their control.
    </li>
  </ul>
    </TextReveal>
    <Paper elevation={0} style={{
      backgroundColor: '#bce2e3',
      borderRadius: '20px'
    }} mdxType="Paper">
  Grief is a normal process that usually reduces in intensity over time. However, if you think your teen’s reaction is
  particularly intense and/or persistent (e.g. major impact on everyday functioning, not improving over time), consider
  seeking professional mental health support.
    </Paper>
    <SingleImage smallGridSize={8} gridSize={6} src={'/images/m6/54-01-AUS-version.svg'} alt="Grieving child" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      